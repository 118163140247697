<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <van-pull-refresh class="myStCourse" v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
                <div class="van_van_radio">
                    <van-radio-group v-model="listQuery.isMain" direction="horizontal" @change="onSearch">
                        <van-radio v-for="(item, key) in typeDiffer" :name="key" :key="key">{{item}}</van-radio>
                    </van-radio-group>
                </div>
                <div class="custom_search_layout">
                    <van-search
                            class="custom_search_01"
                            v-model="listQuery.name"
                            show-action
                            background="transparent"
                            placeholder="请输入课程名称"
                            @search="onSearch">
                        <template #action>
                            <div @click="onSearch">搜索</div>
                        </template>
                    </van-search>
                </div>
                <div class="myTeLive_content">
                    <BarList :type="pageType.course" class="spaces"
                             :list="list"
                             :propType="propType"
                             @callMore="(item)=>{callMore(item, '/courseDetails')}"/>
                </div>
            </van-list>
        </van-pull-refresh>
    </baseContainer>
</template>

<script>
import HeadNav from '../../components/headNav';
import BarList from './../home/components/BarList';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import constant from './../../utils/constant';
import api from './../../api/student';

export default {
    name: "myStCourse",
    components: {
        HeadNav,
        BarList
    },
    mixins: [DropDownRefresh],
    data() {
        return {
            pageType: constant.pageType,
            typeDiffer: constant.typeDiffer,
            listQuery: {
                isMain: constant.typeDifferKey.our,
                name: '',
                current: 1,
                size: 5,
                noCorp: true
            },
            propType: {
                img: 'courseCover',
                name: 'name',
                date: 'startTime',
                user: 'teacherName',
                status: 'status'
            }
        }
    },
    methods: {
        callMore(item, url) {
            this.$utils.callMores(item, url);
        },
        // 获取学生课程列表
        getList() {
            api.getCourseList(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    }
}
</script>

<style scoped lang="less">
    .myStCourse {
        background: #F7F7F7;
        min-height: 100%;
        .van_van_radio {
            padding: 14px 40px 14px;
            background: #FFFFFF;
        }
        .custom_search_layout {
            padding: 20px 40px 20px;
            /*box-shadow: 0 4px 8px 0 rgba(233, 233, 233, 0.5);*/
        }
        .myTeLive_content {
            padding: 10px 40px 40px;
        }
        .van_tabs_layout {
            padding: 0 40px;
        }
        .BarList:first-child {
            margin-top: 0;
        }
    }
</style>